import React, { createContext, useContext } from "react";
import { IAppSettings, IProject, IProjectResults, ITimeEntry, IUser, User } from "../types/types";
import { AlertContext } from "./AlertProvider";
import { Provider } from "@fluentui/react-northstar";
import ReloadButton from "../components/ReloadButton";

const baseUrl = process.env.REACT_APP_APIURL;
export const authUrl = `${baseUrl}/auth`;
export const projectUrl = `${baseUrl}/project`;
export const userUrl = `${baseUrl}/user`;
export const companyUrl = `${baseUrl}/company`;
export const timeEntryUrl = `${baseUrl}/timeEntry`;
export interface ICompany {
    id: string; // Database id
    company_name: string; // Company name
    locked: boolean; // Is the company locked?
}

export interface IProjectCompnayID {
    project_name: string;
    company_id: string;
    project_id: string;
}

export interface IHasHoursResponse {
    companies: string[];
    projects: string[];
    projectCompanyID: IProjectCompnayID[];
}

export interface ICompanyInfoObject {
    company_name: string;
    id: string;
    locked: boolean;
}

export interface IFetchHoursBody {
    company?: string;
    projects?: any[];
    from?: number;
    to?: number;
    getAll?: boolean;
    users?: any[];
}

interface IContextProviderProps {
    children: React.ReactNode;
}

interface IApiContext {
    getServerSideToken: (token: string, tid: string) => Promise<string>;
    getServerSideVersion: () => Promise<{ version: string }>;
    fetchHours: (token: string, body: any) => Promise<ArrayBuffer>;
    fetchYearly: (token: string, body: any) => Promise<ArrayBuffer>;
    fetchHasHours: (token: string) => Promise<IHasHoursResponse>;
    getProjects: (token: string, id: any) => Promise<IProject[]>;
    getCompanies: (token: string) => Promise<ICompany[]>;
    createCompany: (token: string, body: { company_name: any; locked: boolean }) => Promise<ICompany>;
    getCompanyByName: (token: string, name: string) => Promise<ICompanyInfoObject>;
    getUsers: (token: string) => Promise<IUser[]>;
    updateUserAppSettings: (token: string, app_settings: IAppSettings) => Promise<User>;
    setNewUser: (token: any, body: any) => Promise<void>;
    getUser: (token: string) => Promise<User>;
    getTimeEntriesBetween: (token: string, date: { from: any; to: any }) => Promise<any>;
    submitTimeEntry: (token: string, body: ITimeEntry) => Promise<void>;
    updateTimeEntry: (token: string, body: object, id: string) => Promise<void>;
    updateDescription: (token: string, body: { description: any }, id: string | undefined) => Promise<void>;
    deleteTimeEntry: (token: string, id: any, from: any) => Promise<void>;
    fetchProjects: (token: string) => Promise<IProjectResults[]>;
    fetchProject: (token: any, companyID: any, projectName: any) => Promise<IProject>;
    deleteProject: (token: any, id: any) => Promise<void>;
    getProjectSettings: (token: string, name: string) => Promise<IProject[]>;
    getProjectById: (token: string, id: string | undefined) => Promise<IProject>;
    updateProjectSettings: (token: string, body: IProject, id: string | undefined) => Promise<IProject>;
    createProject: (token: string, body: IProject) => Promise<IProject>;
    importHours: (token: string, body: { userData: object }) => Promise<void>;
}

export const ApiContext = createContext<IApiContext>({
    getServerSideToken: async () => {
        return "";
    },
    getServerSideVersion: async () => {
        return { version: "" };
    },
    fetchHours: async () => new ArrayBuffer(0),
    fetchYearly: async () => new ArrayBuffer(0),
    fetchHasHours: async () => {
        return {} as IHasHoursResponse;
    },
    getProjects: async () => [],
    getCompanies: async () => [],
    createCompany: async () => {
        return {} as ICompany;
    },
    getCompanyByName: async () => {
        return {} as ICompanyInfoObject;
    },
    getUsers: async () => [],
    updateUserAppSettings: async () => {
        return {} as User;
    },
    setNewUser: async () => {},
    getUser: async () => {
        return {} as User;
    },
    getTimeEntriesBetween: async () => {},
    submitTimeEntry: async () => {},
    updateTimeEntry: async () => {},
    updateDescription: async () => {},
    deleteTimeEntry: async () => {},
    fetchProjects: async () => [],
    fetchProject: async () => {
        return {} as IProject;
    },
    deleteProject: async () => {},
    getProjectSettings: async () => {
        return [] as IProject[];
    },
    getProjectById: async () => {
        return {} as IProject;
    },
    updateProjectSettings: async () => {
        return {} as IProject;
    },
    createProject: async () => {
        return {} as IProject;
    },
    importHours: async () => {},
});

export function ApiProvider({ children }: IContextProviderProps) {
    const { setErrorMsg } = useContext(AlertContext);
    // import { baseUrl } from "../utils/constants";
    const getHeader = async (token: string) => {
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "x-frontend-version": `${process.env.REACT_APP_VERSION}`,
            // "Access-Control-Allow-Origin": `${baseUrl}`,
        };
    };

    /**
     * Handle errors from backend
     * @param response Response from backend
     * @returns Promise with response
     */
    function handleErrors(response: Response): any {
        return new Promise<void>((resolve, reject) => {
            if (!response.ok) {
                if (response.status === 409) {
                    setErrorMsg(
                        <Provider.Consumer
                            render={(theme) => (
                                <>
                                    <p> Frontend and backend version mismatch! </p>
                                    <p> Please refresh the page. If the problem persists, contact Toni K.</p>
                                    <p>
                                        <strong> Error: {response.status} </strong>
                                        <br />
                                        {response.statusText}
                                    </p>
                                    <ReloadButton theme={theme} />
                                </>
                            )}
                        />
                    );
                } else if (response.status === 401) {
                    setErrorMsg(
                        <Provider.Consumer
                            render={(theme) => (
                                <>
                                    <p> Authentication failed. </p>
                                    <p> Try to refresh the page. If the problem persists, contact Toni K.</p>
                                    <p>
                                        <strong> Error: {response.status} </strong>
                                        <br />
                                        {response.statusText}
                                    </p>
                                    <ReloadButton theme={theme} />
                                </>
                            )}
                        />
                    );
                } else if (response.status === 403) {
                    setErrorMsg(
                        <Provider.Consumer
                            render={(theme) => (
                                <>
                                    <p> Forbidden! </p>
                                    <p> You don&apos;t have permission to view this page. </p>
                                    <p>
                                        <strong> Error: {response.status} </strong>
                                        <br />
                                        {response.statusText}
                                    </p>
                                    <ReloadButton theme={theme} />
                                </>
                            )}
                        />
                    );
                } else if (response.status === 404) {
                    setErrorMsg(
                        <Provider.Consumer
                            render={(theme) => (
                                <>
                                    <p> Not found! </p>
                                    <p> The page you are looking for does not exist. </p>
                                    <p>
                                        <strong> Error: {response.status} </strong>
                                        <br />
                                        {response.statusText}
                                    </p>
                                    <ReloadButton theme={theme} />
                                </>
                            )}
                        />
                    );
                } else {
                    setErrorMsg(
                        <Provider.Consumer
                            render={(theme) => (
                                <>
                                    <p> Something went wrong! </p>
                                    <p> Please refresh the page. If the problem persists, contact Toni K.</p>
                                    <p>
                                        <strong> Error: {response.status} </strong>
                                        <br />
                                        {response.statusText}
                                    </p>
                                    <ReloadButton theme={theme} />
                                </>
                            )}
                        />
                    );
                }

                return reject(response);
            }

            if (response.status === 204) {
                return resolve();
            }

            return response.json().then(resolve).catch(resolve);
        });
    }

    /** Fetch serverside token (AAD) */
    const getServerSideToken = async (token: string, tid: string): Promise<string> => {
        return fetch(`${authUrl}/aadToken`, {
            method: "POST",
            headers: await getHeader(token),
            body: JSON.stringify({
                tid: tid,
                token: token,
            }),
            mode: "cors",
            cache: "default",
        }).then(handleErrors);
    };

    /** Get server side version */
    const getServerSideVersion = async () => {
        return fetch(`${baseUrl}/versionNumber`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            mode: "cors",
            cache: "default",
        }).then(handleErrors);
    };

    /** Fetch hours from DB and save file into oneDrive */
    const fetchHours = async (token: string, body: IFetchHoursBody) => {
        return fetch(`${projectUrl}/reports/hours`, {
            method: "POST",
            headers: await getHeader(token),
            body: JSON.stringify(body),
            mode: "cors",
            cache: "default",
        }).then(async (response) => {
            if (!response.ok) {
                throw await response.json();
            }

            return response.arrayBuffer();
        });
    };

    /** Fetch yearly total for all Offcode users & companies from DB and save file into oneDrive */
    const fetchYearly = async (token: string, body: IFetchHoursBody): Promise<ArrayBuffer> => {
        return fetch(`${projectUrl}/reports/yearlyTotal`, {
            method: "POST",
            headers: await getHeader(token),
            body: JSON.stringify(body),
            mode: "cors",
            cache: "default",
        }).then(async (response) => {
            if (!response.ok) {
                throw await response.json();
            }

            return response.arrayBuffer();
        });
    };

    /** Fetch companies / projects that has time entries for current user  */
    const fetchHasHours = async (token: string): Promise<IHasHoursResponse> => {
        return fetch(`${projectUrl}/hasHours`, {
            method: "GET",
            headers: await getHeader(token),
            // body: JSON.stringify(body),
        }).then(handleErrors);
    };

    /** Fetch projects based on company id */
    const getProjects = async (token: string, id: any): Promise<IProject[]> => {
        return fetch(`${projectUrl}/withCompanyId/${id}`, {
            method: "GET",
            headers: await getHeader(token),
        }).then(handleErrors);
    };

    /** Fetch all companies */
    const getCompanies = async (token: string): Promise<ICompany[]> => {
        return fetch(`${companyUrl}`, {
            method: "GET",
            headers: await getHeader(token),
        }).then(handleErrors);
    };

    /** Create company */
    const createCompany = async (token: string, body: { company_name: any; locked: boolean }) => {
        return fetch(`${companyUrl}`, {
            method: "POST",
            headers: await getHeader(token),
            body: JSON.stringify(body),
        }).then(handleErrors);
    };

    /** Fetch company settings based on company name */
    const getCompanyByName = async (token: string, name: string): Promise<ICompanyInfoObject> => {
        return fetch(`${companyUrl}/name/${name}`, {
            method: "GET",
            headers: await getHeader(token),
        }).then(handleErrors);
    };

    /** Fetch Users */
    const getUsers = async (token: string): Promise<IUser[]> => {
        return fetch(`${userUrl}/?all`, {
            method: "GET",
            headers: await getHeader(token),
        }).then(handleErrors);
    };

    /** Update User app_settings */
    const updateUserAppSettings = async (token: string, app_settings: IAppSettings): Promise<User> => {
        return fetch(`${userUrl}/app_settings`, {
            method: "PUT",
            headers: await getHeader(token),
            body: JSON.stringify(app_settings),
        }).then(handleErrors);
    };

    /** Set new user */
    const setNewUser = async (token: any, body: any) => {
        return fetch(`${userUrl}`, {
            method: "POST",
            headers: await getHeader(token),
            body: JSON.stringify(body),
        }).then(handleErrors);
    };

    /** Fetch User */
    const getUser = async (token: string): Promise<User> => {
        return fetch(`${userUrl}`, {
            method: "GET",
            headers: await getHeader(token),
        })
            .then(handleErrors)
            .catch((err) => {
                //
                throw err;
            });
        // .then((response) => response.json())
        // .then((user: User) => {
        //     return user;
        // })
        // .catch((err) => {
        //    throw err;
        // });
    };

    // /** Get weektotal */
    // const getWeekTotal = async (token, from, to) => {
    //     return fetch(
    //         `${timeEntryUrl}/weekTotal/${from}/${to}`,
    //         {
    //             method: "GET",
    //             headers: await getHeader(token),
    //         }
    //     )
    //         .then(handleErrors)
    //         .catch((err) => {
    //
    //             throw err;
    //         });
    // };

    /** Get timeEntry (from - to) */
    const getTimeEntriesBetween = async (token: string, date: { from: any; to: any }) => {
        const now = new Date();
        return fetch(`${timeEntryUrl}/${date.from}/${date.to}`, {
            method: "GET",
            headers: await getHeader(token),
        }).then((res) => {
            const diff = new Date().getTime() - now.getTime();
            console.debug("Time took to fetch hours", diff);
            return handleErrors(res);
        });
    };

    // /** Get hours (from - to) */
    // const getHoursBetween = async (token, date) => {
    //     return fetch(
    //         `${timeEntryUrl}/hours/${date.from}/${date.to}`,
    //         {
    //             method: "GET",
    //             headers: await getHeader(token),
    //         }
    //     )
    //         .then(handleErrors)
    //         .catch((err) => {
    //
    //             throw err;
    //         });
    // };

    /** Submit time entry insto DB */
    const submitTimeEntry = async (token: string, body: ITimeEntry) => {
        return fetch(`${timeEntryUrl}`, {
            method: "POST",
            headers: await getHeader(token),
            body: JSON.stringify(body),
        }).then(handleErrors);
    };

    /** Update existing time entry */
    const updateTimeEntry = async (token: string, body: object, id: string) => {
        return fetch(`${timeEntryUrl}/` + id, {
            method: "PUT",
            headers: await getHeader(token),
            body: JSON.stringify(body),
        }).then(handleErrors);
    };

    /** Update existing time entry */
    const updateDescription = async (token: string, body: { description: any }, id: string | undefined) => {
        return fetch(`${timeEntryUrl}/description/` + id, {
            method: "PATCH",
            headers: await getHeader(token),
            body: JSON.stringify(body),
            // referrerPolicy: "no-referrer",
            mode: "cors",
        }).then(handleErrors);
    };

    /** Delete timeEntry */
    const deleteTimeEntry = async (token: string, id: any, from: any) => {
        return fetch(`${timeEntryUrl}/${id}/${from}`, {
            method: "DELETE",
            headers: await getHeader(token),
            // body: JSON.stringify({}),
        }).then(handleErrors);
    };

    /** Get projects (for user) */
    const fetchProjects = async (token: string) => {
        return fetch(`${projectUrl}`, {
            method: "GET",
            headers: await getHeader(token),
        }).then(handleErrors);
    };

    /** Get projects based on company_id & projectName */
    const fetchProject = async (token: any, companyID: any, projectName: any) => {
        return fetch(`${projectUrl}/byID/${companyID}/${projectName}`, {
            method: "GET",
            headers: await getHeader(token),
        }).then(handleErrors);
    };

    /** Delete project based on id */
    const deleteProject = async (token: any, id: any) => {
        return fetch(`${projectUrl}/${id}`, {
            method: "DELETE",
            headers: await getHeader(token),
        }).then(handleErrors);
    };

    //NOTE TO MYSELF: USE THIS FORMAT FOR ALL CALLS!
    /** Fetch project setting based on project name*/
    const getProjectSettings = async (token: string, name: string): Promise<IProject[]> => {
        return await fetch(`${projectUrl}/byName/${name}`, {
            method: "GET",
            headers: await getHeader(token),
        }).then(handleErrors);
    };

    //Get project by id
    const getProjectById = async (token: string, id: string | undefined) => {
        return await fetch(`${projectUrl}/withId/${id}`, {
            method: "GET",
            headers: await getHeader(token),
        }).then(handleErrors);
    };

    /** Update existing project settings */
    const updateProjectSettings = async (token: string, body: IProject, id: string | undefined) => {
        return await fetch(`${projectUrl}/` + id, {
            method: "PUT",
            headers: await getHeader(token),
            body: JSON.stringify(body),
        }).then(handleErrors);
    };

    /** Create project */
    const createProject = async (token: string, body: IProject): Promise<IProject> => {
        return fetch(`${projectUrl}`, {
            method: "POST",
            headers: await getHeader(token),
            body: JSON.stringify(body),
        }).then(handleErrors);
    };

    /**Import hours from file */
    const importHours = async (token: string, body: { userData: object }) => {
        return fetch(`${companyUrl}/importTimeTable`, {
            method: "POST",
            headers: await getHeader(token),
            body: JSON.stringify(body),
        }).then(handleErrors);
    };

    return (
        <ApiContext.Provider
            value={{
                getServerSideToken,
                getServerSideVersion,
                fetchHours,
                fetchYearly,
                fetchHasHours,
                getProjects,
                getCompanies,
                createCompany,
                getCompanyByName,
                getUsers,
                updateUserAppSettings,
                setNewUser,
                getUser,
                getTimeEntriesBetween,
                submitTimeEntry,
                updateTimeEntry,
                updateDescription,
                deleteTimeEntry,
                fetchProjects,
                fetchProject,
                deleteProject,
                getProjectSettings,
                getProjectById,
                updateProjectSettings,
                createProject,
                importHours,
            }}
        >
            {children}
        </ApiContext.Provider>
    );
}
