import { EyeSlashIcon } from "@fluentui/react-icons-northstar";
import { Flex, Tooltip, Button } from "@fluentui/react-northstar";
import React from "react";
import * as Constants from "../../utils/constants";
import { IAppSettings, IUser } from "../../types/types";

interface IWeektableHideCellProps {
    projectId: string;
    disHasHours: boolean;
    projectName: string;
    jwtoken: string;
    user: IUser;
    visibleProjects: string[] | undefined;
    setVisibleProjects: (projects: string[]) => void;
    update: () => void;
    updateUserAppSettings: (jwtoken: string, appSettings: IAppSettings) => Promise<IUser>;
}

export default function WeektableHideCell(props: IWeektableHideCellProps) {
    // Utility function to remove objects from an array
    const removeObjectsFromArray = (arr: any[]) => {
        return arr.filter((item) => typeof item !== "object");
    };

    // Handle hide event for the project
    const handleHide = (projectId: string, hasHours: boolean) => async () => {
        try {
            if (hasHours || projectId === Constants.DEFAULT_PROJECT_ID) {
                console.warn("Cannot hide project");
                return;
            }

            if (props.visibleProjects) {
                const currArr = removeObjectsFromArray([...props.visibleProjects]);

                const index = currArr.indexOf(projectId);
                if (index !== -1) {
                    currArr.splice(index, 1);
                }

                const appSettings = props.user?.app_settings;
                if (appSettings) {
                    appSettings.visible_projects = currArr;
                    const user = await props.updateUserAppSettings(props.jwtoken, appSettings);
                    props.setVisibleProjects(user.app_settings.visible_projects);
                    props.update();
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const tooltip = props.disHasHours ? (
        <div>
            <div>Hours applied,</div>
            <div>cannot hide this week</div>
        </div>
    ) : props.projectId === Constants.DEFAULT_PROJECT_ID ? (
        `Cannot hide default project`
    ) : (
        `Hide project: ${props.projectName}`
    );

    return (
        <div>
            <Flex gap="gap.smaller">
                <Tooltip
                    trigger={
                        <Button
                            icon={
                                <EyeSlashIcon
                                    disabled={props.disHasHours || props.projectId === Constants.DEFAULT_PROJECT_ID}
                                    style={{
                                        color: "#C4314B",
                                    }}
                                />
                            }
                            text
                            iconOnly
                            onClick={handleHide(props.projectId, props.disHasHours)}
                        />
                    }
                    content={tooltip}
                />
            </Flex>
        </div>
    );
}
