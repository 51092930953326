import { Button, Input, Provider, Text } from "@fluentui/react-northstar";
import React, { useContext, useEffect, useState } from "react";
import AlertExampleUrgent from "../components/Alert";
import CustomLoader from "../components/CustomLoader";
import ProjectSettings from "../components/ProjectSettings";
import { User, IProject } from "../types/types";
import * as Constants from "../utils/constants";
import { ApiContext, ICompany } from "../providers/ApiProvider";
import { AppContext } from "../providers/ContextProvider";
import { AlertContext } from "../providers/AlertProvider";
import ErrorMessage from "../components/ErrorMessage";

export default function SettingsTab() {
    const { theme, providerStyle, user, token, versionNumber, setUser } = useContext(AppContext);
    const { getCompanies, getUser, getProjectSettings, updateUserAppSettings } = useContext(ApiContext);
    const { errorMsg } = useContext(AlertContext);

    const [companiesList, setCompaniesList] = useState<string[]>([]);
    const [companiesData, setCompaniesData] = useState<ICompany[]>([]);
    const [defaultProjectName, setDefaultProjectName] = useState<string>();
    const [success, setSuccess] = useState<boolean>(false);
    const [msg, setMsg] = useState<string | undefined>();

    useEffect(() => {
        if (token) {
            _getCompanies(token);
        }
    }, [token]);

    const _getCompanies = async (token: string) => {
        const companies = await getCompanies(token);
        const listOfCompanies: string[] = [];
        companies.forEach((company) => {
            listOfCompanies.push(company.company_name);
        });

        setCompaniesList(listOfCompanies);
        setCompaniesData(companies);
    };

    return (
        <Provider theme={theme} style={providerStyle}>
            {!errorMsg ? (
                <div>
                    {user && token ? (
                        <div
                            style={{
                                padding: 20,
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "start",
                                height: "100vh",
                                width: "100vw",
                                overflow: "hidden",
                                paddingLeft: 50,
                                paddingRight: 50,
                                paddingBottom: 50,
                                display: "flex",
                            }}
                        >
                            <h2>
                                {`Configure project settings`}{" "}
                                <Text
                                    color={"red"}
                                    weight="bold"
                                    size="largest"
                                    content={`${user.is_project_manager ? "Project Manager" : ""}${
                                        user.is_admin ? "Admin" : ""
                                    }`}
                                />
                            </h2>
                            <ProjectSettings
                                jwtoken={token}
                                user={user}
                                companiesList={companiesList}
                                companiesData={companiesData}
                                setMessage={setMsg}
                                setSuccess={setSuccess}
                            />

                            <h2>
                                {`Configure User settings`}{" "}
                                <Text
                                    color={"red"}
                                    weight="bold"
                                    size="largest"
                                    content={`${user.is_project_manager ? "Project Manager" : ""}${
                                        user.is_admin ? "Admin" : ""
                                    }`}
                                />
                            </h2>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "left",
                                    gap: "0.5rem",
                                }}
                            >
                                <Input
                                    variables={
                                        {
                                            // inputPadding: "0.5rem",
                                        }
                                    }
                                    input={{
                                        style: {
                                            textAlign: "left",
                                            // fontSize: "30px",
                                            // fontWeight: "bold",
                                            // minHeight: "50px",
                                            // maxWidth: "80%",
                                            minWidth: "300px",
                                        },
                                    }}
                                    autoComplete="off"
                                    type="text"
                                    placeholder={
                                        !user.app_settings.default_project ||
                                        user.app_settings.default_project.default_project_string === ""
                                            ? "Type default project name here"
                                            : `Current one is: "${user.app_settings.default_project.default_project_string}"`
                                    }
                                    id={`default-project-id`}
                                    // value={this.state.user.app_settings.default_project.default_project_string}
                                    onChange={(e) => {
                                        const target = e.target as HTMLTextAreaElement;
                                        setDefaultProjectName(target.value);
                                        // setProjectName(target.value);
                                    }}
                                    inline
                                />
                                <Button
                                    content="Update default project"
                                    disabled={defaultProjectName === ""}
                                    onClick={async () => {
                                        try {
                                            let _user: User = await getUser(token);
                                            const companyName = Constants.DEFAULT_COMPANY_NAME;
                                            const projectName = Constants.DEFAULT_PROJECT_NAME;
                                            let companyId: string;
                                            let projectId: string | undefined;
                                            //TODO: change how getCompanySettings returns data
                                            const projectSettings: IProject[] = await getProjectSettings(
                                                token,
                                                projectName
                                            );
                                            if (projectSettings.length > 0) {
                                                companyId = projectSettings[0].company_id;
                                                projectId = projectSettings[0].id;
                                            } else {
                                                throw new Error("Project not found");
                                            }
                                            const appSettings = _user.app_settings;
                                            appSettings.default_project = {
                                                company_name: companyName,
                                                company_id: companyId,
                                                project_name: projectName,
                                                project_id: projectId!,
                                                default_project_string: defaultProjectName || "",
                                            };

                                            _user = await updateUserAppSettings(token, appSettings).catch((err) => {
                                                throw (
                                                    err +
                                                    ". This probably means that nothing was changed because the new default project name is the same as the old one."
                                                );
                                            });
                                            setUser(_user);
                                            setSuccess(true);
                                            setMsg(
                                                `Default project name updated to "${_user.app_settings.default_project.default_project_string}"`
                                            );
                                        } catch (error) {
                                            console.error(error);
                                            setSuccess(false);
                                            setMsg(JSON.stringify(error));
                                        }
                                    }}
                                />
                                {msg ? (
                                    <div
                                        style={{
                                            maxWidth: "300px",
                                        }}
                                    >
                                        <AlertExampleUrgent
                                            msg={msg}
                                            hideMessage={() => setMsg(undefined)}
                                            success={success}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    left: 5,
                                    bottom: 5,
                                    color: "gray",
                                }}
                            >
                                Version: {versionNumber}
                            </div>
                        </div>
                    ) : (
                        <CustomLoader msg="Fetching data..." />
                    )}
                </div>
            ) : (
                <ErrorMessage errorMsg={errorMsg} />
            )}
        </Provider>
    );
}
