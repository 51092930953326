import React, { useContext, useState } from "react";
import {
    Dialog,
    Form,
    FormInput,
    Divider,
    RadioGroup,
    FormButton,
    FormCheckbox,
    CheckboxProps,
    FormDatepicker,
    Provider,
    Flex,
    FlexItem,
} from "@fluentui/react-northstar";
import CreatableCompanySearch from "./CreatableCompanySearch";
import CreatableProjectSearch from "../components/CreatableProjectSearch";
import { IDropdownElement } from "./WeekTable";
import { IProject, IUser } from "../types/types";
import { ApiContext, ICompany } from "../providers/ApiProvider";
import { MAX_PROJECT_NAME_LENGTH } from "../utils/constants";

interface IAddProjectDialogTypes {
    dropdownArr: IDropdownElement[];
    token: string;
    user: IUser;
    passChildData: (data: IDropdownElement) => void;
    handleSelectedProject: (project: IDropdownElement) => void;
}
const ADD_NEW_COMPANY = "Add new company";

export default function AddProjectDialog(props: IAddProjectDialogTypes) {
    const { createProject } = useContext(ApiContext);
    const [projects, setProjects] = useState<IDropdownElement[]>([]);
    const [companies, setCompanies] = useState<string[]>([ADD_NEW_COMPANY]);
    const [selectedCompany, setSelectedCompany] = useState<string>();
    const [selectedProject, setSelectedProject] = useState<string | number>();
    const [popup, setPopup] = useState<boolean>(false);
    const [isPrivate, setIsPrivate] = useState<boolean>(true);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [startDate, setStartDate] = useState<number | null>(null);
    const [endDate, setEndDate] = useState<number | null>(null);
    const [locked, setLocked] = useState<boolean | undefined | "mixed">(false);

    const clearFields = () => {
        console.log("Clearing fields");
        setProjects([]);
        setCompanies([ADD_NEW_COMPANY]);
        setSelectedCompany("");
        setSelectedProject("");
        setPopup(false);
        setIsPrivate(true);
        setIsDisabled(true);
        setStartDate(null);
        setEndDate(null);
        setLocked(false);
    };

    const createNewProject = async (name: string, companyID: string) => {
        const body: IProject = {
            company_id: companyID,
            owner_oid: "",
            project_name: name,
            members: isPrivate ? [props.user.oid] : ["offcodeGroup"],
            admins: [],
            entry_locked: locked ? true : false,
            tags: ["tag1"],
            private: isPrivate,
            active: {
                from: startDate,
                to: endDate,
            },
            budget: {
                used: null,
                total: null,
            },
        };
        const ret = await createProject(props.token, body);
        const newDropdownElement: IDropdownElement = {
            label: companyID,
            project_id: ret.id!,
            value: name,
            private: isPrivate,
            key: companyID + name,
            image: null,
        };
        setProjects([...projects, newDropdownElement]);
        props.passChildData(newDropdownElement);
        //clear all fields
        clearFields();
        return ret;
    };

    const handleCompanies = (company: ICompany) => {
        setSelectedCompany(company.id);
        setCompanies([...companies, company.company_name]);
        setIsDisabled(false);
    };

    const handleDisabled = (disabled: boolean | ((prevState: boolean) => boolean)) => {
        setIsDisabled(disabled);
    };

    return (
        <Dialog
            key="dialog"
            backdrop
            open={popup}
            trapFocus
            closeOnOutsideClick={false}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
            styles={({ theme: { siteVariables } }) => ({
                color: siteVariables.colorScheme.brand.foreground,
            })}
            onCancel={() => {
                setPopup(false);
            }}
            content={
                <Form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        const projectName = (e.target as HTMLFormElement)["projectName"].value;
                        if (selectedCompany) {
                            try {
                                const project = await createNewProject(projectName, selectedCompany);
                                const dropdownElement: IDropdownElement = {
                                    label: selectedCompany,
                                    project_id: project.id!,
                                    value: projectName,
                                    private: isPrivate,
                                    key: selectedCompany + projectName,
                                    image: null,
                                };
                                props.handleSelectedProject(dropdownElement as IDropdownElement);

                                // props.handleSelectedProject(dropdownElement);
                                setPopup(false);
                            } catch (error) {
                                console.error(error);
                            }
                        }
                    }}
                >
                    <Provider.Consumer
                        render={(theme) => (
                            <CreatableCompanySearch
                                selectedCompany={handleCompanies}
                                styles={theme!}
                                handleDisabled={handleDisabled}
                                user={props.user}
                            />
                        )}
                    />
                    <FormInput
                        label="Project Name"
                        name="projectName"
                        required
                        maxLength={MAX_PROJECT_NAME_LENGTH}
                        minLength={1}
                        disabled={isDisabled}
                        value={selectedProject}
                        onChange={(e, data) => {
                            if (data) setSelectedProject(data.value);
                        }}
                    />
                    <Divider />
                    <FormDatepicker
                        label="Select active start date"
                        onDateChange={(e, data) => {
                            if (data) setStartDate(data.value.valueOf());
                        }}
                        disabled={isDisabled}
                    />
                    <FormDatepicker
                        label="Select active end date"
                        onDateChange={(e, data) => {
                            if (data) setEndDate(data.value.valueOf());
                        }}
                        disabled={isDisabled}
                    />
                    {props.user.is_admin ? (
                        <RadioGroup
                            vertical
                            defaultCheckedValue="private"
                            items={["private", "public"].map((group) => ({
                                key: group,
                                value: group,
                                name: "group",
                                label: group,
                                "aria-label": "orange",
                            }))}
                            onCheckedValueChange={async (e, p: any) => {
                                // eslint-disable-next-line react/prop-types
                                if (p.value == "public") {
                                    setIsPrivate(false);
                                } else {
                                    setIsPrivate(true);
                                }
                            }}
                        />
                    ) : (
                        <div></div>
                    )}
                    <Divider />
                    <FormCheckbox
                        label="Entry locked"
                        toggle
                        onChange={(e, data: CheckboxProps = {}) => {
                            setLocked(data.checked);
                        }}
                        disabled={isDisabled}
                    />
                    <Flex>
                        <FormButton primary content="Submit" disabled={isDisabled} />
                        <FlexItem push>
                            <FormButton primary content="Cancel" onClick={() => setPopup(false)} />
                        </FlexItem>
                    </Flex>
                </Form>
            }
            trigger={
                <div>
                    <Provider.Consumer
                        render={(theme) => (
                            <CreatableProjectSearch
                                projectList={props.dropdownArr}
                                styles={theme}
                                selectedProject={props.handleSelectedProject}
                                passSelectedProject={setSelectedProject}
                                setPopUp={setPopup}
                            />
                        )}
                    />
                </div>
            }
        />
    );
}
