import React, { useContext } from "react";

import { Table } from "@fluentui/react-northstar";
import * as Generators from "../../actions/generators";
import * as Constants from "../../utils/constants";
import { IProjectResults, ITimeEntry, IUser, IWeekData } from "../../types/types";
import { sortOn, moveOnTop } from "../../utils/helpers";
import { IDropdownElement } from "../WeekTable";
import WeektableDateCell from "./WeektableDateCell";
import WeektableWeekTotalCell from "./WeektableWeekTotalCell";
import { HIDE_COLUMN_BUTTON_STYLES, PROJECT_COLUMN_STYLES, WEEK_TOTAL_HEADER_STYLES } from "./utils";
import WeektableHideCell from "./WeektableHideCell";
import WeekTableProjectNameCell from "./WeekTableProjectNameCell";
import { ApiContext } from "../../providers/ApiProvider";

interface IWeektableDataRowsProps {
    dbHours: ITimeEntry[];
    weekTotal: IWeekData["weekTotal"];
    field: IWeekData["dates"];
    dayTotal: { [x: string]: number };
    jwtoken: string;
    visibleProjects: string[] | undefined;
    selectedDays: number[];
    user: IUser;
    overflow: "auto" | "visible";
    projectsArr: string[];
    projectsData: IProjectResults[] | undefined;
    dropdownArr: IDropdownElement[];
    loadingCells: { [key: string]: boolean };
    setField: (field: IWeekData["dates"]) => void;
    fetchTimeEntries: (week: any[]) => Promise<void>;
    setVisibleProjects: (projects: string[] | undefined) => void;
    setProjectsData: (projects: IProjectResults[] | undefined) => void;
    setDropdownArr: (arr: IDropdownElement[] | undefined) => void;
    setLoadingCells: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
    update: () => void;
}

export default function WeektableDataRows(props: IWeektableDataRowsProps) {
    const { updateUserAppSettings } = useContext(ApiContext);
    // Utility function to filter out the selected project from the dropdown array
    const filterDropdownArray = (arr: IDropdownElement[], project: IDropdownElement) => {
        return arr.filter((item) => item !== project);
    };

    // Handle project change event
    const handleProjectOnChange = async (project: IDropdownElement) => {
        if (!props.dropdownArr || !props.visibleProjects || !props.user?.app_settings) return;

        const newArr = filterDropdownArray(props.dropdownArr, project);
        const projectId = project.project_id;

        // Check that newItem is not already in array
        if (props.visibleProjects.includes(projectId)) return;

        const updatedVisibleProjects = [...props.visibleProjects, projectId];
        const appSettings = {
            ...props.user.app_settings,
            visible_projects: updatedVisibleProjects,
        };

        try {
            const user = await updateUserAppSettings(props.jwtoken, appSettings);
            props.setVisibleProjects(user.app_settings.visible_projects);
            props.setDropdownArr(newArr);
        } catch (error) {
            console.error("Failed to update user app settings", error);
        }
    };

    //This is called when Dialog component project list is updated.
    // This means that we should fetch new project list from database
    const handleNewProject = (data: any) => {
        console.log("Data from dialog", data);
        props.update();
    };

    // Generate new project row
    const generateProjectRow = (index: number) => {
        const row: any[] = [];
        let project: IProjectResults | null = null;
        if (props.projectsData) {
            project = props.projectsData[index];
        }
        if (project) {
            const companyName = project.company_name;
            // const companyID = project.company!.id;
            const projectID = project.id!;
            const disHasHours = props.weekTotal[projectID] ? true : false;
            const projectName = project.project_name;
            //Generate row if it has hours marked or if it's included in visibleProjects or if it's the default project
            if (
                disHasHours ||
                props.visibleProjects?.includes(projectID) ||
                projectID === Constants.DEFAULT_PROJECT_ID
            ) {
                const isPrivate = project.private;
                const activeFrom = project.active.from;
                const activeTo = project.active.to;
                const isLocked = project.entry_locked;

                const privColor = isPrivate ? "green" : projectID === Constants.DEFAULT_PROJECT_ID ? "red" : "orange";

                const privText = isPrivate
                    ? "Private Project"
                    : projectID === Constants.DEFAULT_PROJECT_ID
                    ? "Default Project (Allways visible)"
                    : "Public Project";

                // let hasDescription: string | null = null;

                // First cell of the row (company name & project name)
                const styles = PROJECT_COLUMN_STYLES;
                styles["overflow"] = "scroll"; //append default style with overflow: scroll
                row.push({
                    key: projectID,
                    content: (
                        <WeekTableProjectNameCell
                            projectName={projectName}
                            companyName={companyName}
                            privColor={privColor}
                            privText={privText}
                            projectId={projectID}
                            isLocked={isLocked}
                        />
                    ),
                    hashours: disHasHours.toString(), // With this flag we control which projects are to be seen. (Automatically shown if hours)
                    styles: styles,
                });

                // Monday-Sunday cells (1-7) for each project (0: previous week last day, 1-7: current week, 8: next week first day)
                for (let i = 1; i < 8; i++) {
                    const date = props.selectedDays[i];
                    const fieldName = projectID + "_" + date;

                    let locked = isLocked;
                    let placeholder = "";
                    if (!locked) {
                        if (activeFrom) {
                            if (!(activeFrom <= date.valueOf())) {
                                locked = true;
                            }
                        }
                        if (activeTo) {
                            if (!(date.valueOf() <= activeTo)) {
                                locked = true;
                            }
                        }
                    }
                    if (locked) {
                        if (projectID === Constants.DEFAULT_PROJECT_ID) placeholder = "";
                        else placeholder = "locked";
                    }

                    /** Date cells */
                    row.push({
                        key: `${projectID}-${i}`,
                        truncateContent: true,
                        styles: {
                            minWidth: "100px",
                            maxWidth: "100px",
                            marginRight: 0,
                        },
                        content: (
                            <WeektableDateCell
                                field={props.field}
                                projectID={projectID}
                                fieldName={fieldName}
                                dbHours={props.dbHours}
                                date={date}
                                locked={locked}
                                jwtoken={props.jwtoken}
                                loadingCells={props.loadingCells}
                                placeholder={placeholder}
                                project={project}
                                selectedDays={props.selectedDays}
                                user={props.user}
                                setField={props.setField}
                                setLoadingCells={props.setLoadingCells}
                                fetchTimeEntries={props.fetchTimeEntries}
                                setProjectsData={props.setProjectsData}
                                projectsData={props.projectsData}
                            />
                        ),
                    });
                }

                /** Cell for total hours */
                row.push({
                    key: `${projectID}-total`,
                    content: <WeektableWeekTotalCell projectID={projectID} weekTotal={props.weekTotal} />,
                    truncateContent: true,
                    styles: WEEK_TOTAL_HEADER_STYLES,
                });

                /** Cell for hide icon */
                row.push({
                    key: `${projectID}-hide`,
                    content: (
                        <WeektableHideCell
                            projectId={projectID}
                            disHasHours={disHasHours}
                            projectName={projectName}
                            jwtoken={props.jwtoken}
                            user={props.user}
                            visibleProjects={props.visibleProjects}
                            update={props.update}
                            setVisibleProjects={props.setVisibleProjects}
                            updateUserAppSettings={updateUserAppSettings}
                        />
                    ),
                    styles: HIDE_COLUMN_BUTTON_STYLES,
                });
            }
        }
        return row;
    };

    // This collects all project rows and generate row object for table
    const generateRows = () => {
        let rows: any[] = [];
        const projectsToBeSeenArr: any[] = [];

        // Generate rows based on array of projects found
        rows = props.projectsArr.map((project, index) => ({
            key: String(project),
            items: generateProjectRow(index),
            styles: { height: "auto" },
        }));

        sortOn(rows, "key"); //Sort rows based on project name

        moveOnTop(rows, Constants.DEFAULT_PROJECT_ID); //Move default project on top

        // Check if project has hours set. If so add it to rows, otherwise skip.
        // Added also check if user selected project to be seen even without hours.
        rows.forEach((row) => {
            if (row.items.length !== 0) {
                // Check if row has any items
                const projectID = row.key.split("_")[1]; // Get projectID from row key
                // This handles the visible project selection.
                // If project has hours or is selected to be seen (or is default project), add it to rows
                if (
                    row.items[0].hashours ||
                    props.visibleProjects!.includes(projectID) ||
                    projectID === Constants.DEFAULT_PROJECT_ID
                ) {
                    projectsToBeSeenArr.push(row);
                }
            }
        });

        // Select project row at the bottom of the weektable
        projectsToBeSeenArr.push({
            key: "selectProjectRow",
            items: Generators.generateSelectProject(
                props.dropdownArr,
                props.user!,
                props.jwtoken,
                handleProjectOnChange,
                handleNewProject
            ),
        });

        // Day total cell row at the bottom of the weektable
        projectsToBeSeenArr.push({
            key: "dayTotalCell",
            items: Generators.generateDayTotalCell(props.selectedDays, props.dayTotal, props.weekTotal),
        });

        return projectsToBeSeenArr;
    };

    return (
        <Table
            style={{
                maxWidth: "90vw",
                maxHeight: "200vh",
                overflowX: props.overflow,
                overflowY: "visible",
            }}
            key="weekTable"
            header={Generators.generateHeaders(props.selectedDays)}
            rows={generateRows()}
        />
    );
}
