import React, { useContext, useEffect, useState } from "react";
import { IProject, IProjectResults, ITimeEntry, IUser } from "../../types/types";
import { Loader, Input, Tooltip } from "@fluentui/react-northstar";
import DescriptionDialog from "../AddDescriptionDialog";
import * as Constants from "../../utils/constants";
import { cloneDeep } from "lodash";
import { ApiContext } from "../../providers/ApiProvider";
import { AppContext } from "../../providers/ContextProvider";

interface IWeektableDateCell {
    date: number;
    placeholder: string;
    projectID: string;
    fieldName: string;
    project: IProjectResults;
    selectedDays: number[];
    loadingCells: { [key: string]: boolean };
    jwtoken: string;
    field: { [key: string]: string };
    locked: boolean;
    dbHours: ITimeEntry[];
    user: IUser;
    projectsData: IProjectResults[] | undefined;
    setField: (field: { [key: string]: string }) => void;
    setLoadingCells: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
    setProjectsData: (projectsData: IProjectResults[] | undefined) => void;
    fetchTimeEntries: (week: any[]) => Promise<void>;
}

export default function WeektableDateCell(props: IWeektableDateCell) {
    const { submitTimeEntry, updateTimeEntry, deleteTimeEntry, updateDescription, updateProjectSettings } =
        useContext(ApiContext);
    const { isAnyCellLoading, setIsAnyCellLoading, theme } = useContext(AppContext);

    const [prev, setPrev] = useState<string | number | null>(null);

    const cellKey = `${props.projectID}-${props.date.valueOf()}`;
    const isLoading = props.loadingCells[cellKey];

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (isLoading) {
                setIsAnyCellLoading(true);
            } else {
                setIsAnyCellLoading(false);
            }
        }

        return () => {
            mounted = false;
        };
    }, [isLoading, setIsAnyCellLoading]);

    /**
     * Get project index from dbHours array if dbhours has project with given id and from date is same as given(current cell date)
     * @param projectID project id
     * @param date curren cell date
     * @returns project index from dbHours array or -1 if not found
     */
    const getProjectIndex = (projectID: any, date: { valueOf: any }) => {
        let ret: any = -1;
        ret = props.dbHours.findIndex((obj: ITimeEntry) => {
            return obj.project_id === projectID && obj.from === date.valueOf();
        });
        return ret;
    };

    /**
     * Check if projects object has project with given id and from date is same as given(current cell date)
     * @param projectID project id
     * @param date current cell date
     * @returns boolean true if project is found and false if not
     */
    const getProjectObject = (projectID: any, date: { valueOf: () => any }) => {
        let ret: boolean = false;
        ret = props.dbHours.some((project) => {
            return project.project_id === projectID && project.from === date.valueOf();
        });
        return ret;
    };

    /**
     *
     * @param projectID project id
     * @param date current cell date
     * @param onlyID if true returns only id of time entry
     * @returns either fulls string (projectID + date) or timeEntry database id or undefined if project is not found
     */
    const handleID = (projectID: any, date: { valueOf: () => any }, onlyID: boolean) => {
        let id: string | undefined = undefined;

        if (getProjectObject(projectID, date)) {
            const idx = getProjectIndex(projectID, date);
            if (onlyID) {
                id = props.dbHours[idx].id; //timeEntry database id
            } else {
                id = `${projectID}-${date.valueOf()}`; //Full string (projectid + date)
            }
        }
        return id;
    };

    const getDescription = (projectID: string, date: { valueOf: any }) => {
        let description = null;
        props.dbHours.some((entry) => {
            if (entry.project_id === projectID && entry.from === date.valueOf() && entry.description !== "") {
                description = entry.description;
                return null;
            }
            return null;
        });

        return description;
    };

    const id = handleID(props.projectID, props.date, false); // Full string (projectid + date) or undefined if project is not found
    const timeEntryID = handleID(props.projectID, props.date, true); // timeEntry database id for Description dialog
    const hasDescription = getDescription(props.projectID, props.date);

    // submit new time entry
    const _submitTimeEntry = async (data: ITimeEntry) => {
        await submitTimeEntry(props.jwtoken, data);
        await props.fetchTimeEntries(props.selectedDays);
    };

    //Update existing time entry
    const _updateTimeEntry = async (data: ITimeEntry, id: any) => {
        await updateTimeEntry(props.jwtoken, data, id);
        await props.fetchTimeEntries(props.selectedDays);
    };

    const _deleteTimeEntry = async (projectID: any, from: number) => {
        await deleteTimeEntry(props.jwtoken, projectID, from);
        await props.fetchTimeEntries(props.selectedDays);
    };

    const _handleDescriptionReady = async (body: { description: string }, cellKey: string, id: string) => {
        try {
            props.setLoadingCells((prev: { [key: string]: boolean }) => ({ ...prev, [cellKey]: true }));
            await updateDescription(props.jwtoken, body, id);
            await props.fetchTimeEntries(props.selectedDays);
        } catch (error) {
            console.error(error);
        } finally {
            props.setLoadingCells((prev: { [key: string]: boolean }) => ({ ...prev, [cellKey]: false }));
        }
    };

    const handleOnChange = (data: { value: any }) => {
        let current = data.value.replace(",", ".");
        if (current > Constants.MAXHOURS) current = Constants.MAXHOURS;
        if (current < Constants.MINHOURS) current = Constants.MINHOURS;
        return current;
    };

    const handleOnBlur = async (
        target: EventTarget & HTMLInputElement,
        prev: string | number | null,
        projectID: any,
        date: { valueOf: () => any },
        project: IProjectResults
    ) => {
        const cellKey = `${projectID}-${date.valueOf()}`;
        props.setLoadingCells((prev: { [key: string]: boolean }) => ({ ...prev, [cellKey]: true }));

        try {
            let current: string | number = target.value as unknown as number;
            if (isNaN(current)) current = "0";
            const from: number = Number(date.valueOf());
            const to: number = from + 3600 * 1000 * parseFloat(current as string);
            const today = new Date();

            if (current !== prev) {
                if (current == 0) {
                    // Skip if prev was empty
                    if (prev !== "" && prev !== "0") {
                        //delete time entry from database
                        console.info("Delete time entry");
                        await _deleteTimeEntry(projectID, from);
                    } else {
                        // Clear the input field (show nothing in it)
                        const updatedField = { ...props.field };
                        updatedField[props.fieldName] = "";
                        props.setField(updatedField);
                        props.setLoadingCells((prev: { [key: string]: boolean }) => ({ ...prev, [cellKey]: false }));
                        return;
                    }
                } else {
                    // We use id field to tell that we have already timeEntry in database for this field
                    const idx = getProjectIndex(projectID, date);
                    if (idx === -1) {
                        // Handle new time entry
                        const newEntry: ITimeEntry = {
                            project_id: projectID,
                            created_by: props.user?.id || "",
                            created: today.valueOf(),
                            modified: today.valueOf(),
                            description: "",
                            from: from,
                            to: to,
                            approved: false,
                        };
                        await _submitTimeEntry(newEntry);
                    } else {
                        // Handle update time entry
                        const existingEntry = props.dbHours[idx];
                        existingEntry.from = from;
                        existingEntry.to = to;
                        existingEntry.modified = today.valueOf();
                        await _updateTimeEntry(existingEntry, existingEntry.id);
                    }
                }

                if (project.budget) {
                    console.debug("Project has budget: ", project.budget);
                    const body: IProjectResults = cloneDeep(project);
                    // delete body.company;

                    let _prev = prev;
                    if (typeof _prev === "string") _prev = parseFloat(_prev);
                    if (_prev === null || isNaN(_prev)) _prev = 0;

                    if (typeof current === "string") current = parseFloat(current);
                    if (_prev === null || isNaN(current)) current = 0;

                    body.budget.used = project.budget.used ? project.budget.used - (_prev - current) : current;

                    const ret: IProject = await updateProjectSettings(props.jwtoken, body, body.id);
                    const newObject = cloneDeep(props.projectsData); //make deep copy of original object

                    const index = newObject?.findIndex((x) => {
                        //find right object based on id
                        if (x.id === ret.id) return true;
                        else return false;
                    });

                    if (newObject && index !== -1 && index) {
                        newObject[index].budget = ret.budget; //update with new value
                    }

                    props.setProjectsData(newObject);
                }
            }
        } catch (error) {
            // setErrorMsg("Error updating time entry");
        } finally {
            props.setLoadingCells((prev: { [key: string]: boolean }) => ({ ...prev, [cellKey]: false }));
        }
    };

    return (
        <>
            {isLoading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        minWidth: "80px",
                        height: "100%",
                        backgroundColor: theme?.siteVariables?.colorScheme.default.background2,
                        borderRadius: "0.25rem",
                    }}
                >
                    <Loader
                        styles={{
                            width: "100%",
                            minHeight: "40px",
                            display: "flex",
                        }}
                    />
                </div>
            ) : (
                <div>
                    <Input
                        variables={{
                            inputPadding: "0.1rem",
                        }}
                        input={{
                            style: {
                                textAlign: "center",
                                fontSize: "normal",
                                fontWeight: "semibold",
                                minHeight: "40px",
                                filter: isAnyCellLoading ? "blur(1px)" : "none",
                            },
                        }}
                        autoComplete="off"
                        disabled={
                            (props.locked && props.projectID !== Constants.DEFAULT_PROJECT_ID) || isAnyCellLoading
                        }
                        type="text"
                        fluid
                        placeholder={props.placeholder}
                        id={id}
                        icon={
                            id !== undefined ? (
                                <Tooltip
                                    content={"Add description"}
                                    trigger={
                                        <DescriptionDialog
                                            id={timeEntryID}
                                            token={props.jwtoken}
                                            hasDescription={hasDescription}
                                            cellKey={cellKey}
                                            submitReady={_handleDescriptionReady}
                                        />
                                    }
                                />
                            ) : null
                        }
                        value={props.field[props.fieldName] ? props.field[props.fieldName] : ""}
                        onBlur={(e) => handleOnBlur(e.target, prev, props.projectID, props.date, props.project)}
                        onFocus={(e) => setPrev(e.target.value)}
                        onChange={(e, data) => {
                            if (data) {
                                const newValue = handleOnChange(data);
                                const updatedField = { ...props.field };
                                updatedField[props.fieldName] = newValue;
                                props.setField(updatedField);
                            }
                        }}
                    />
                </div>
            )}
        </>
    );
}
